@import "fonts.scss";
@import "variable.scss";

@mixin brand_text($font, $leter_spacing, $font_weight,$float: left) {
  font-size: $font;
  text-transform: uppercase;
  letter-spacing: $leter_spacing;
  float: $float;
  font-weight: $font_weight;
}

@mixin title_block($line: true, $fs: 16px) {
  font-size: $fs;
  position: relative;
  font-weight: 800;
  margin: 0;
  text-transform: uppercase;
  @if $line == true {
    margin-bottom: 30px;
  }
  .all {
    position: absolute;
    right: 0;
    font-size: 12px;
    font-weight: 700;
    color: #383838;
    top: 5px;
    i {
      color: color($colors, primary);
      margin-left: 5px;
    }
  }
  @if $line == true {
    &:after {
      content: ' ';
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 15px;
      height: 2px;
      background-color: color($colors, primary);
    }    
  }
}

body {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color: color($colors, dark);
  font-size: 1.4em;
}

h1, h2, h3, h4, h5, h6,
.line div {
  font-weight: 800;
  font-family: 'Raleway';
  color: #000;
  margin: 10px 0;
}

h1 {
  font-size: 48px;
}
h2 {
  font-size: 40px;
}
h3 {
  font-size: 32px;
}
h4 {
  font-size: 26px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}

.input-group {
  .btn {
    height: 40px;
  }
}

a, .btn {
  transition: $general-transition;
  -webkit-transition: $general-transition;
  -o-transition: $general-transition;
  -moz-transition: $general-transition;
}

a {
  color: color($colors, primary);
  &:hover, &:focus {
    color: color($colors, secondary);
  }

  &.facebook {
    background-color: color($colors, facebook);
    color: #fff !important;
  }
  &.twitter {
    background-color: color($colors, twitter);
    color: #fff !important;
  }
  &.youtube {
    background-color: color($colors, youtube);
    color: #fff !important;
  }
  &.googleplus {
    background-color: color($colors, googleplus);
    color: #fff !important;
  }
  &.instagram {
    background-color: color($colors, instagram);
    color: #fff !important;
  }
  &.tumblr {
    background-color: color($colors, tumblr);
    color: #fff !important;
  }
  &.dribbble {
    background-color: color($colors, dribbble);
    color: #fff !important;
  }
  &.linkedin {
    background-color: color($colors, linkedin);
    color: #fff !important;
  }
  &.skype {
    background-color: color($colors, skype);
    color: #fff !important;
  }
  &.rss {
    background-color: color($colors, rss);
    color: #fff !important;
  }
  &.github {
    background-color: color($colors, github);
    color: #fff !important;
  }
  &.whatsapp {
    background-color: color($colors, whatsapp);
    color: #fff !important;
  }
  &.snapchat {
    background-color: color($colors, snapchat);
    color: #000 !important;
  }
  &.foursquare {
    background-color: color($colors, foursquare);
    color: #fff !important;
  }
  &.pinterest {
    background-color: color($colors, pinterest);
    color: #fff !important;
  }
  &.wordpress {
    background-color: color($colors, wordpress);
    color: #fff !important;
  }
  &.reddit {
    background-color: color($colors, reddit);
    color: #fff !important;
  }
  &.hackernews {
    background-color: color($colors, hackernews);
    color: #fff !important;
  }
  &.designernews {
    background-color: color($colors, designernews);
    color: #fff !important;
  }
  &.yahoo {
    background-color: color($colors, yahoo);
    color: #fff !important;
  }
  &.buffer {
    background-color: color($colors, buffer);
    color: #fff !important;
  }
  &.vimeo {
    background-color: color($colors, vimeo);
    color: #fff !important;
  }
  &.twitch {
    background-color: color($colors, twitch);
    color: #fff !important;
  }
}

.main-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  background-color: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  background-image: url('../images/spinner.svg');
  background-size: 100%;
  width: 80px;
  height: 80px;
  box-shadow: 0 0 40px rgba(0,0,0,0.05);
  -webkit-box-shadow: 0 0 40px rgba(0,0,0,0.05);
  -moz-box-shadow: 0 0 40px rgba(0,0,0,0.05);
  -ms-box-shadow: 0 0 40px rgba(0,0,0,0.05);
  -o-box-shadow: 0 0 40px rgba(0,0,0,0.05);
  z-index: 999999;
}

.bold {
  font-weight: 600;
}

.spacing {
  margin: 10px 0 10px 0;
}

.font-lato {
  font-family: 'Lato';
}

.font-raleway {
  font-family: 'Raleway';
}

.font-merriweather {
  font-family: 'Merriweather';
}

.badge-primary {
  background-color: color($colors, primary);
}

.badge-secondary {
  background-color: color($colors, secondary);
}

.badge-warning {
  background-color: color($colors, warning);
}

.badge-danger {
  background-color: color($colors, danger);
}

.badge-success {
  background-color: color($colors, success);
}

.badge-info {
  background-color: color($colors, info);
}

.badge-grey {
  background-color: color($colors, grey);
}

.for-mobile, .for-tablet {
  display: none !important;
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
  border: 0;
  margin: 0;
  li {
    font-size: 12px;
    a {
      color: color($colors, primary);
    }
  }
}

.pagination {
  margin-bottom: 10px;
  li {
    a {
      color: #333;
      border-radius: 50% !important;
      margin: 0 2px;
      border-color: transparent;
      background-color: #f2f2f2;
      width: 35px;
      height: 35px;
      line-height: 32px;
      text-align: center;
      padding: 0;
    }
  }
  li.active {
    a {
      color: #fff;
      transform: scale(1.16) translateX(-3px);
      -moz-transform: scale(1.16) translateX(-3px);
      -webkit-transform: scale(1.16) translateX(-3px);
      -o-transform: scale(1.16) translateX(-3px);
      margin-left: 8px;
      border-color: transparent;
      background-color: color($colors, primary);
      &:hover, &:focus {
        border-color: transparent;
        background-color: color($colors, primary);
      }
    }
  }
}

.pagination-help-text {
  color: #666;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
}

.magz-list {
  padding-left: 18px;
  &.square {
    li {
      list-style: square;
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  display: none;
  z-index: 2000;
}

header.primary {
  //position: fixed;
  z-index: 1999;
  top: 0;
  left: 0;
  width: 100%;
  transition: $general-transition;
  -webkit-transition: $general-transition;
  -o-transition: $general-transition;
  -moz-transition: $general-transition;
  &.up {
    top: -172px;
    nav.menu {
      box-shadow: 0 0 40px rgba(0,0,0,.1);
      border-top-color: transparent;
      border-bottom: none;
      .brand {
        display: block;
      }
      ul.nav-list {
        margin-left: 40px;
        li {
          &:first-child {
            margin-left: 0;
          }
        }
      }
      ul:not(.dropdown-menu) {
        > li {
          > a {
            padding-top: 0;
            padding-bottom: 0;
            height: $nav_height - 2;
            line-height: 52px;
            .badge {
              top: 2px;
            }
          }
        }
      }
      .dropdown-menu {
        top: 53px;
        .dropdown-menu {
          top: 0;
        }
      }
    }
  }
}

section {
  padding-top: 20px;
  padding-bottom: 40px;
  &.first {
    padding-top: 240px;
    transition: $general-transition;
    -webkit-transition: $general-transition;
    -o-transition: $general-transition;
    -moz-transition: $general-transition;
  }
  &.grey {
    background-color: color($colors, 'grey');
  }
  &.most-popular {
    padding: 80px 0;
    > .row {
      margin: 0;
      display: table;
      [class^="col-"] {
        display: table-cell;
        float: none;
      }
    }
    .image {
      background-size: cover;
      background-position: center;
    }
    .details {
      background-color: color($colors, 'mostpopular');
      .inner {
        padding: 80px 20px;
        .badge, h2, .detail, p {margin-bottom: 20px;}
        .badge {
          border: 2px solid color($colors, 'dark');
          border-radius: 0;
          padding: 5px 10px;
          text-transform: uppercase;
          font-weight: 400;
          background-color: transparent;
          color: color($colors, 'dark');
        }
        h2 {
          font-size: 30px;
          line-height: 38px;
        }
        .detail {
          display: flex;
          display: -webkit-box;
          display: -moz-box;
          font-size: 10px;
          text-transform: uppercase;
          letter-spacing: .5px;
          font-weight: 500;
          .time {
            margin-right: 10px;
          }
        }
        p {
          line-height: 26px;
          margin-bottom: 40px;
        }
        .more {
          font-weight: 600;
          letter-spacing: .5px;
          div, i {float: left;}
          i {
            margin-left: 13px;
            &:before {
              font-size: 25px;
            }
          }
        }
      }
    }
  }
  &.maps {
    padding-top: 60px;
    margin-bottom: -45px;
  }
  &.not-found {
    padding: 50px 0;
    text-align: center;
    .code {
      font-size: 12em;
      text-align: center;
      font-weight: 700;
      position: relative;
      display: inline-block;
      &:after, &:before {
        position: absolute;
        right: -100px;
        top: 20px;
        width: 100px;
        height: 2px;
        background-color: #f2f2f2;
        transform: rotate(-30deg);
        content: ' ';
      }
      &:after {
        bottom: 50px;
        top: initial;
        left: -120px;
        right: initial;
        transform: rotate(-30deg);
      }
    }
    h1 {
      font-size: 24px;
      line-height: 44px;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin: 0;
    }
    p.lead {
      font-size: 16px;
      color: #999;
    }
    .search-form {
      display: inline-block;
      margin: 0 auto;
      form {
        width: 300px;
        display: inline-block;
      }
    }
    .link {
      padding: 2px 0;
    }
  }
  &.search {
    .search-result {
      margin-top: 10px;
      margin-bottom: 30px;
      color: #999;
    }
  }
}

.form-control {
  border-radius: 0;
  height: 40px;
  &:focus {
    border-color: #989898;
    box-shadow: 0 1px 2px #ddd;
  }
}

.btn {
  font-size: 15px;
  border-radius: 0;
  padding-left: 20px;
  padding-right: 20px;
  border-color: transparent;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: .5px;
  &.btn-lg {
    border-radius: 0;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  &.btn-sm {
    border-radius: 0;
    padding: 5px 10px;
    font-size: 12px;
  }
  &:focus {
    outline: 0;
    box-shadow: none;
    border-color: transparent;
  }
  &.btn-rounded {
    border-radius: 3px;
  }
  i {
    margin-right: 5px;
  }
}

.btn-social {
  font-weight: 400;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover {
    color: #fff;
    opacity: .8;
  }
}

.btn-primary {
  background-color: color($colors, primary);
  border-color: color($colors, primary);
  &:disabled,&.disabled, &:hover, &:active, &:active:hover, &:active:focus, &:focus {
    background-color: darken(color($colors, primary), 20%);
    border-color: darken(color($colors, primary), 20%);
    box-shadow: none;
    outline: 0;
  }
  &:active:hover {
    background-color: darken(color($colors, primary), 25%);
  }
}

.btn-success {
  background-color: color($colors, success);
  color: #fff !important;
  border-color: color($colors, success);
  &:disabled,&.disabled, &:hover, &:active, &:active:hover, &:active:focus, &:focus {
    background-color: darken(color($colors, success), 20%);
    border-color: darken(color($colors, success), 20%);
    outline: 0;
    box-shadow: none;
  }
  &:active:hover {
    background-color: darken(color($colors, success), 25%);
  }
}

.btn-warning {
  background-color: color($colors, warning);
  color: #fff !important;
  border-color: color($colors, warning);
  &:disabled,&.disabled, &:hover, &:active, &:active:hover, &:active:focus, &:focus {
    background-color: darken(color($colors, warning), 20%);
    border-color: darken(color($colors, warning), 20%);
    outline: 0;
    box-shadow: none;
  }
  &:active:hover {
    background-color: darken(color($colors, warning), 25%);
  }
}

.btn-danger {
  background-color: color($colors, danger);
  color: #fff !important;
  border-color: color($colors, danger);
  &:disabled,&.disabled, &:hover, &:active, &:active:hover, &:active:focus, &:focus {
    background-color: darken(color($colors, danger), 20%);
    border-color: darken(color($colors, danger), 20%);
    outline: 0;
    box-shadow: none;
  }
  &:active:hover {
    background-color: darken(color($colors, danger), 25%);
  }
}

.btn-info {
  background-color: color($colors, info);
  color: #fff !important;
  border-color: color($colors, info);
  &:disabled,&.disabled, &:hover, &:active, &:active:hover, &:active:focus, &:focus {
    background-color: darken(color($colors, info), 20%);
    border-color: darken(color($colors, info), 20%);
    outline: 0;
    box-shadow: none;
  }
  &:active:hover {
    background-color: darken(color($colors, info), 25%);
  }
}

.btn-default {
  background-color: color($colors, grey);
  color: #fff !important;
  border-color: color($colors, grey);
  &:disabled,&.disabled, &:hover, &:active, &:active:hover, &:active:focus, &:focus {
    background-color: darken(color($colors, grey), 20%);
    border-color: darken(color($colors, grey), 20%);
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }
  &:active:hover {
    background-color: darken(color($colors, grey), 25%);
  }
}

.btn-magz {
  background-color: transparent;
  color: color($colors, primary);
  transition: $general-transition;
  -webkit-transition: $general-transition;
  -o-transition: $general-transition;
  -moz-transition: $general-transition;
  border-color: color($colors, primary);
  position: relative;
  overflow: hidden;
  z-index: 2;
  &:after {
    position: absolute;
    bottom: -20px;
    left: 20px;
    width: 5px;
    height: 5px;
    opacity: 0;
    border-radius: 50%;
    content: ' ';
    background-color: color($colors, primary);
    z-index: -1;
    transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -webkit-transition: all .3s;
  }
  &:focus:after, &:hover:after {
    transform: scale(200);
    -webkit-transform: scale(200);
    -moz-transform: scale(200);
    -o-transform: scale(200);
    opacity: 1;
    transition: all 1s;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
  }
  &:focus {
    color: #fff;
  }
  &:hover {
    background-color: transparent;
    color: #fff !important;
  }
  i {
    margin-left: 10px;
  }
  &.white {
    color: #fff;
    &:after {
      background-color: #fff;
    }
    &:focus, &:hover {
      color: color($colors, primary) !important;
    }
  }
}

/* Social */

.social {
  padding: 0;
  margin: 0;
  li {
    display: inline-block;
    a {
      display: block;
      text-align: center;
      width: 40px;
      height: 40px;
      line-height: 40px;
      margin: 0 5px;
      margin-bottom: 8px;
      position: relative;
      transition: all .5s;
      -moz-transition: all .5s;
      -webkit-transition: all .5s;
      -o-transition: all .5s;
      svg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        rect {
          width: inherit;
          height: inherit;
          fill: transparent;
          stroke: transparent;
          stroke-dasharray: 0;
          stroke-width: 3;
          transition: all 1.5s;
          -moz-transition: all 1.5s;
          -webkit-transition: all 1.5s;
          -o-transition: all 1.5s;
        }
      }
      i:before {
        transform: scale(1.3);
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -o-transform: scale(1.3);
      }
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        opacity: .8;
      }
    }
  }

  &.trp {
    li {
      a {
        &:hover {
          background-color: transparent;
          svg {
            rect {
              stroke: #fff;
              stroke-dasharray: 200;
            }
          }
        }
        &.facebook:hover {
          color: color($colors, facebook) !important;
          svg rect {
            stroke: color($colors, facebook);
          }
        }
        &.twitter:hover {
          color: color($colors, twitter) !important;
          svg rect {
            stroke: color($colors, twitter);
          }
        }
        &.youtube:hover {
          color: color($colors, youtube) !important;
          svg rect {
            stroke: color($colors, youtube);
          }
        }
        &.googleplus:hover {
          color: color($colors, googleplus) !important;
          svg rect {
            stroke: color($colors, googleplus);
          }
        }
        &.instagram:hover {
          color: color($colors, instagram) !important;
          svg rect {
            stroke: color($colors, instagram);
          }
        }
        &.tumblr:hover {
          color: color($colors, tumblr) !important;
          svg rect {
            stroke: color($colors, tumblr);
          }
        }
        &.dribbble:hover {
          color: color($colors, dribbble) !important;
          svg rect {
            stroke: color($colors, dribbble);
          }
        }
        &.linkedin:hover {
          color: color($colors, linkedin) !important;
          svg rect {
            stroke: color($colors, linkedin);
          }
        }
        &.skype:hover {
          color: color($colors, skype) !important;
          svg rect {
            stroke: color($colors, skype);
          }
        }
        &.rss:hover {
          color: color($colors, rss) !important;
          svg rect {
            stroke: color($colors, rss);
          }
        }
        &.github:hover {
          color: color($colors, github) !important;
          svg rect {
            stroke: color($colors, github);
          }
        }
        &.whatsapp:hover {
          color: color($colors, whatsapp) !important;
          svg rect {
            stroke: color($colors, whatsapp);
          }
        }
        &.snapchat:hover {
          color: color($colors, snapchat) !important;
          svg rect {
            stroke: color($colors, snapchat);
          }
        }
        &.foursquare:hover {
          color: color($colors, foursquare) !important;
          svg rect {
            stroke: color($colors, foursquare);
          }
        }
        &.pinterest:hover {
          color: color($colors, pinterest) !important;
          svg rect {
            stroke: color($colors, pinterest);
          }
        }
        &.wordpress:hover {
          color: color($colors, wordpress) !important;
          svg rect {
            stroke: color($colors, wordpress);
          }
        }
        &.reddit:hover {
          color: color($colors, reddit) !important;
          svg rect {
            stroke: color($colors, reddit);
          }
        }
        &.hackernews:hover {
          color: color($colors, hackernews) !important;
          svg rect {
            stroke: color($colors, hackernews);
          }
        }
        &.designernews:hover {
          color: color($colors, designernews) !important;
          svg rect {
            stroke: color($colors, designernews);
          }
        }
        &.yahoo:hover {
          color: color($colors, yahoo) !important;
          svg rect {
            stroke: color($colors, yahoo);
          }
        }
        &.buffer:hover {
          color: color($colors, buffer) !important;
          svg rect {
            stroke: color($colors, buffer);
          }
        }
        &.vimeo:hover {
          color: color($colors, vimeo) !important;
          svg rect {
            stroke: color($colors, vimeo);
          }
        }
        &.twitch:hover {
          color: color($colors, twitch) !important;
          svg rect {
            stroke: color($colors, twitch);
          }
        }
      }
    }
  }
}

.form-group {
  label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
    &.fw {
      display: block;
    }
    .required {
      color: color($colors, danger);
      font-weight: 600;
      font-size: 16px;
      &:before {
        content: '*';
      }
    }
  }
  .help-block {
    font-size: 12px;
    margin-top: -3px;
  }
  .form-control {
    border-radius: 0;
    font-family: 'Lato';
    font-weight: 300;
    &:focus {
      border-color: #989898;
      box-shadow: 0 1px 2px #ddd;
    }

    &.rounded {
      border-radius: 4px;
    }
  }

  &.has-error {
    .form-control {
      border-color: color($colors, danger);
    }
    .control-label {
      color: color($colors, danger);
    }
    .help-block {
      color: color($colors, danger);
    }
  }

  &.has-success {
    .form-control {
      border-color: color($colors, success);
    }
    .control-label {
      color: color($colors, success);
    }
    .help-block {
      color: color($colors, success);
    }
  }

  &.has-warning {
    .form-control {
      border-color: color($colors, warning);
    }
    .control-label {
      color: color($colors, warning);
    }
    .help-block {
      color: color($colors, warning);
    }
  }

  &.floating {
    position: relative;
    margin-top: 10px;
    .form-control {
      padding-left: 0;
      padding-right: 0;
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-width: 2px;
      box-shadow: none;
    }
    &.stacked {    
      label {
        font-size: 12px;
        position: absolute;
        top: -13px;
        left: 0;
        background-color: #fff;
      }
    }
    &.focus {
      label {
        position: absolute;
        top: 10px;
        left: 0;
        transition: $general-transition;
        -o-transition: $general-transition;
        -webkit-transition: $general-transition;
        -moz-transition: $general-transition;
        z-index: -1;
      }

      .form-control {
        background-color: transparent;
        border-radius: 0;
        &::-webkit-input-placeholder {
          opacity: 0;
          transition: $general-transition;
          -moz-transition: $general-transition;
          -webkit-transition: $general-transition;
          -o-transition: $general-transition;
        }
      }

      &.focused {        
        label {
          top: -13px;
          left: 0;
          font-size: 12px;
        }
        .form-control {        
          &::-webkit-input-placeholder {
            opacity: 1;
          }
        }
      }
    }
  }
}

.firstbar {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  background-color: color($colors, firstbar);
  .brand {
    float: left;
    margin: 20px 0;
    a {
      text-decoration: none;
    }

    img {
      width: 200px;
      display: block;
      float: left;
    }

    .text {
      @include brand_text(6em,8px,100,none);
      margin-top: -25px;
      margin-bottom: -20px;
    }
  }

  .search {
    margin-top: 20px;
    margin-left: 55px;
    .form-group {
      margin-bottom: 0;
      .input-group-btn {
        .btn {
          line-height: 0;
        }
      }
    }
    .help-block {
      div {
        text-transform: uppercase;
        float: left;
        font-weight: 500;
        font-size: 11px;
        margin-right: 10px;
        color: color($colors, 'primary');
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          float: left;
          padding: 0 8px 0 0;
          display: block;
          font-size: 11px;
          font-weight: 400;
          a {
            color: color($colors, 'dark');
          }
        }
      }
    }
  }
  .nav-icons {
    float: right;
    padding: 0;
    margin: 0;
    margin-top: 27px;
    li {
      display: block;
      float: left;
      margin-left: 20px;
      a {
        display: inline-block;
        color: color($colors, 'dark');
        i {
          font-size: 18px;
          float: left;
          width: 25px;
          text-align: left;
        }
        div {
          float: left;
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 1px;
          margin-top: 3px;
        }
        &:hover {
          color: color($colors, 'primary');
        }
      }
    }
  }
}

.headline {
  margin-bottom: 20px;
  .item {
    padding: 5px;
    width: 100%;
    a {
      color: color($colors, dark);
      font-size: 14px;
      text-transform: capitalize;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      display: inline-block;
      .badge {
        background-color: color($colors, 'primary');
        margin-right: 5px;
      }
    }
  }
  .nav {
    display: inline-block;
    float: left;
    margin-right: 20px;
    position: relative;
    z-index: 2;
  }
  .carousel-control {
    text-shadow: none;
    background-color: transparent;
    background-image: none;
    position: relative;
    display: inline-block;
    color: #000;
    width: auto;
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    margin-right: -5px;
    &.right {
      border-left: 0;
    }
  }
  .carousel-inner {
    overflow: hidden;
    width: initial;
  }
}

.article, article, .most-popular {
  p {
    font-family: 'Merriweather', sans-serif;
    font-weight: 400;
    color: rgba(0,0,0, .85);
  }
  .time, .category {
    font-size: 10px;
    letter-spacing: .5px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.article {
  margin-bottom: 35px;
}

article {
  figure {
    height: 250px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
    display: inline-block;
    img {
      width: 100%;
    }
  }

  .detail {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
  }
}

.article {
  .inner {
    box-shadow: 0 1px 2px #ddd;
  }
  .padding {
    padding: 15px;
    padding-top: 0;
  }
  h2 {
    text-transform: capitalize;
    font-size: 20px;
    margin: 0;
    line-height: 28px;
    margin-bottom: 10px;
    a {
      color: #333;
      text-decoration: none;
      &:hover {
        color: color($colors, primary);
      }
    }
  }
  p {
    line-height: 24px;
    font-size: 14px;
  }
}

article {
  .detail {
    display: flex;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .time, .category {
    margin: 0 10px;
    color: color($colors, dark);
  }
  .category {
    margin: 0 10px;
    a {
      color: color($colors, primary);
    }
  }
  .time {
    margin: 0;
  }
  footer {
    text-align: right;
    .btn-primary {
      background-color: transparent;
      border-color: color($colors, primary);
      color: color($colors, primary);
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      overflow: hidden;
      transition: all .5s;
      -moz-transition: all .5s;
      -o-transition: all .5s;
      -webkit-transition: all .5s;
      &:after {
        position: absolute;
        content: ' ';
        bottom: 0;
        left: 20px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        transition: all .5s;
        -moz-transition: all .5s;
        -webkit-transition: all .5s;
        -o-transition: all .5s;
        z-index: -1;
      }
      &:hover {
        &:after {
          transform: scale(20);
          -moz-transform: scale(20);
          -webkit-transform: scale(20);
          -o-transform: scale(20);
          background-color: color($colors, primary);
        }
        color: #fff;
      }
      &:focus {
        outline: 0;
      }
    }
  }
}

.more {
  overflow: hidden;
  height: 43px;
  div {
    &:last-child {
      opacity: 0;
    }
    transition: all 1s;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    i:before {
      font-size: 30px;
    }
  }
  &:hover div {
    &:last-child {
      opacity: 1;
    }
    &:first-child {
      margin-top: -43px;
      margin-bottom: 20px;
    }
  }
}

.love {
  color: #989898;
  float: left;
  margin-top: 10px;
  position: relative;
  i {
    display: inline-block;
    float: left;
    margin-top: 1px;
  }
  i:before {
    font-size: 28px;
  }
  div {
    display: inline-block;
    margin-left: 60px;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 5px;
  }
  &.active {
    color: color($colors, primary);
  }
  i.animated {
    animation-name: love;
    -webkit-animation-name: love;
    -moz-animation-name: love;
    -o-animation-name: love;
    animation-fill-mode: backwards;
    -moz-animation-fill-mode: backwards;
    -o-animation-fill-mode: backwards;
    -webkit-animation-fill-mode: backwards;
    animation-duration: .5s;
    -moz-animation-duration: .5s;
    -webkit-animation-duration: .5s;
    -o-animation-duration: .5s;
    position: absolute;
    left: 0;
    top: 0;
  }
}

@keyframes love {
  0% {
    top: 0px;
    transform: rotate(0);
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
  }

  20% {
    top: -10px;
    transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -webkit-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    opacity: .8;
  }

  40% {
    top: -20px;
    transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -webkit-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    opacity: .6;
  }

  60% {
    top: -30px;
    transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    opacity: .4;
  }

  80% {
    top: -40px;
    transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    opacity: .2;
  }

  100% {
    top: -50px;
    transform: rotate(0);
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    opacity: 0;
  }
}

@-webkit-keyframes love {
  0% {
    top: 0px;
    transform: rotate(0);
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
  }

  20% {
    top: -10px;
    transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -webkit-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    opacity: .8;
  }

  40% {
    top: -20px;
    transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    -webkit-transform: rotate(-20deg);
    -o-transform: rotate(-20deg);
    opacity: .6;
  }

  60% {
    top: -30px;
    transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    opacity: .4;
  }

  80% {
    top: -40px;
    transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    opacity: .2;
  }

  100% {
    top: -50px;
    transform: rotate(0);
    -moz-transform: rotate(0);
    -webkit-transform: rotate(0);
    -o-transform: rotate(0);
    opacity: 0;
  }
}


.best-of-the-week {
  margin-top: 20px;
  h1 {
    font-size: 16px;
    margin-bottom: 25px;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    color: color($colors, dark);
    div.text {
      background-color: #fff;
      display: inline-block;
      padding: 10px;
      padding-right: 20px;
      padding-left: 0;
    }
    &:after {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 3px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      background-color: #ddd;
      z-index: -1;
    }
    .carousel-nav {
      .next, .prev {
        width: 35px;
        height: 35px;
        background-color: #fff;
        line-height: 34px;
      }
    }
  }
  .article {
    figure {
      height: 175px;
    }
    h2 {
      font-size: 16px;
      margin: 0 0 5px 0;
      a {
        color: #333;
        &:hover {
          color: color($colors, primary);
        }
      }
    }
  }
}

.featured {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 550px;
  margin-bottom: 20px;
  overflow: hidden;
  .inner {
    padding: 20px;
  }
  figure {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .overlay {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: transparent;
    background-image: linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.59) 100%);
    background-image: -webkit-linear-gradient(transparent 0%,transparent 50%,rgba(0,0,0,0.1) 56%,rgba(0,0,0,0.6) 93%,rgba(0,0,0,0.59) 100%);
  }
  .details {
    padding: 20px 30px;
    position: absolute;
    bottom: 10px;
    h1, .category, .time {
      color: #fff;
      margin: 0;
    }
    .category {
      margin-bottom: 10px;
      a {
        font-size: 12px;
        font-weight: 400;
        padding: 5px 15px;
        display: inline-block;
        border-radius: 30px;
        background-color: #fff;
        color: #000;
        text-decoration: none;
        transition: all .3s;
        -moz-transition: all .3s;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        &:hover {
          opacity: .8;
        }
      }
    }
    h1 {
      margin-bottom: 5px;
      text-transform: capitalize;
      line-height: 1.4em;
      font-size: 28px;
      font-weight: 700;
      a {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

.topbar {
  position: relative;
  background-color: color($colors, topbar);
  display: inline-block;
  width: 100%;
  .inner {
    border-bottom: 1px solid #ddd;
    display: inline-block;
    width: 100%;
  }
  .right {
    float: right;
    ul {
      li {
        &:last-child {
          a {
            margin-right: 0;
          }
        }
      }
    }
  }
  .left {
    float: left;
    ul {
      li {
        &:first-child {
          a {
            margin-left: 0;
          }
        }
      }
    }
  }
  ul {
    padding: 0;
    margin: 0;
    margin-bottom: -5px;
    &.info {
      margin-left: 4px;
      li {
        a {
          letter-spacing: .3px;
          i {
            display: inline-block;
            transform: scale(1.5);
            -webkit-transform: scale(1.5);
            -o-transform: scale(1.5);
            -moz-transform: scale(1.5);
            margin-right: 5px;
          }
        }
      }
    }
    li {
      float: left;
      display: block;
      a {
        color: color($colors, dark);
        margin: 0 7px;
        padding: 10px 0;
        display: block;
        text-decoration: none;
        position: relative;
        z-index: 1;
        transition: all .5s;
        -moz-transition: all .5s;
        -webkit-transition: all .5s;
        -o-transition: all .5s;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: .5px;
        i {
          display: none;
        }
      }
      &.active a {
      }
      a {
        &:hover {
          color: color($colors, primary);
        }
      }
      &.active a:after, a:hover:after {
        height: 100%;
      }
    }
  }
}

.menu {
  position: relative;
  display: inline-block;
  height: $nav_height;
  width: 100%;
  line-height: 23px;
  background-color: color($colors, 'navbg');
  border-top: 1px solid color($colors, 'navborder');
  border-bottom: 1px solid color($colors, 'navborder');
  .container {
    /* Mega menu support */
    position: relative;
  }
  .brand {
    display: none;
    float: left;
    width: 100px;
    height: $nav_height;
    line-height: 52px;
    img {
      width: 100%;
    }
    a {
      text-decoration: none;
    }
  }
  .mobile-toggle {
    font-size: 32px;
    line-height: 0;
    float: right;
    margin-left: 20px;
    display: none;
    padding: 11px 0;
  }
  ul.nav-list
   {
    > li {
      &:first-child {
        margin-left: -17px;
      }
    }
  }
  ul {
    margin: 0 auto;
    display: inline-block;
    padding: 0;
    z-index: 9;
    > li {
      float: left;
      display: block;
      &.magz-dropdown-megamenu {
        position: static;
      }
      > a {
        display: block;
        color: color($colors, 'navtext');
        padding: 11px 17px;
        text-decoration: none;
        letter-spacing: .5px;
        font-weight: 700;
        line-height: 32px;
        font-family: 'Raleway', sans-serif;
        position: relative;
        z-index: 1;
        i {
          margin-left: 5px;
        }
        .badge {
          background-color: color($colors, primary);
          position: absolute;
          top: 2px;
          right: -5px;
        }
        &:after {
          content: ' ';
          position: absolute;
          z-index: 1;
          top: 100%;
          left: 0;
          width: 0;
          height: 2px;
          background-color: color($colors, primary);
          transition: all 1s;
          -moz-transition: all 1s;
          -webkit-transition: all 1s;
          -o-transition: all 1s;
          margin-top: -2px;
        }
      }
      &.active a:after, &:hover > a:after {
        width: 100%;
      }
      &:hover > .dropdown-menu {
        visibility: visible;
        opacity: 1;
        transition: all .5s;
        -moz-transition: all .5s;
        -webkit-transition: all .5s;
        -o-transition: all .5s;
        transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -webkit-transform: scale(1);
      }
      .dropdown-menu {
        border-radius: 0;
        border: none;
        top: 54px;
        box-shadow: 0 20px 60px #ddd;
        opacity: 0;
        visibility: hidden;
        transform: scale(.5);
        -moz-transform: scale(.5);
        -webkit-transform: scale(.5);
        -o-transform: scale(.5);
        transition: all .2s;
        -moz-transition: all .2s;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        &.megamenu {
          display: block;
          margin: 0;
          left: 15px;
          right: 15px;
          z-index: 1999;
          background-color: #fff;
          .megamenu-inner {
            padding: 20px;
            padding-bottom: 15px;
          }
          .megamenu-title {
            @include title_block(false);
            margin-bottom: 15px;
          }
          .vertical-menu {
            width: 100%;
            li {
              display: inline-block;
              width: 100%;
              float: none;
              a {
                padding: 5px 0;
                height: auto;
                line-height: 34px;
                border-bottom: 1px solid #f2f2f2;
                i {
                  margin-left: 0;
                  width: 20px;
                  float: left;
                }
                &:after {
                  top: 100%;
                }
              }
            }
          }
          article {
            margin-bottom: 0;
            .inner {
              box-shadow: none;
            }
            figure {
              height: 170px;
              margin-bottom: 0;
            }
            .padding {
              padding: 0;
              h2 {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
        > li {
          float: none;
          display: block;
          position: relative;
          &.divider {
            padding: 10px 0;
            background-color: #fff;
            position: relative;
            &:before {
              content: ' ';
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
              -webkit-transform: translate(0, -50%);
              -moz-transform: translate(0, -50%);
              -o-transform: translate(0, -50%);
              left: 0;
              height: 1px;
              width: 100%;
              background-color: #f2f2f2;
            }
          }
          > a {
            background-color: #fff;
            width: $dropdown_menu_width;
            position: relative;
            overflow: hidden;
            transition: all .3s;
            -moz-transition: all .3s;
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            padding: 7px 20px;
            &:after {
              position: absolute;
              content: ' ';
              top: 100%;
              left: 50px;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              transition: all .5s;
              -moz-transition: all .5s;
              -webkit-transition: all .5s;
              -o-transition: all .5s;
              background-color: transparent;
              z-index: -1;
            }
            i {
              float: right;
            }
            .icon {
              float: none;
              width: 25px;
              float: left;
              margin-left: -2px;
            }
          }
          &.active a:after {
            transition: all 1s;
            -moz-transition: all 1s;
            -webkit-transition: all 1s;
            -o-transition: all 1s;
            transform: scale(82);
            -webkit-transform: scale(82);
            -moz-transform: scale(82);
            -o-transform: scale(82);
            background-color: color($colors, primary);
            width: 5px;
          }
          &:hover > a {
            &:after {
              transition: all 1s;
              -moz-transition: all 1s;
              -webkit-transition: all 1s;
              -o-transition: all 1s;
              transform: scale(82);
              -webkit-transform: scale(82);
              -moz-transform: scale(82);
              -o-transform: scale(82);
              background-color: color($colors, primary);
              width: 5px;
            }
            color: #fff;
          }
          ul {
            position: absolute;
            left: $dropdown_menu_width;
            top: 0;
          }
        }
      }
    }
  }
}

section .container {
  padding-left: 15px;
  padding-right: 15px;
}

.newsletter {
  padding: 20px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  font-size: 14px;
  color: #989898;
  .icon {
    text-align: center;
    margin-bottom: 20px;
    i:before {
      font-size: 9em;
    }
    &.spin {
      h1 {
        margin-top: 0;
      }
      i:before {
        animation-name: loading;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
    &.success {
      h1 {
        margin-top: 0;
        color: color($colors, success);
      }
      i:before {
        color: color($colors, success);
      }
    }
    &.error {
      h1 {
        margin-top: 0;
        color: color($colors, primary);
      }
      i:before {
        color: color($colors, primary);
      }
    }
    h1 {
      margin: 0;
      font-size: 24px;
      margin-top: -20px;
      color: #989898;
    }
    &.spin h1 {}
  }
  p {
    margin-top: 10px;
    line-height: 22px;
  }
  .form-control {
    font-size: 14px;
    height: 40px;
    &:focus {
      box-shadow: none;
    }
  }
  .btn i:before {
    font-size: 18px;
  }
}

.article-list {
  margin-bottom: 50px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  .badge {
    position: absolute;
    top: 0;
    right: 15px;
    border-radius: 0;
    background-color: color($colors, primary);
  }
  .inner {
    display: inline-block;
    width: 100%;
  }
  figure {
    width: 300px;
    height: 195px;
    overflow: hidden;
    background-color: #000;
    float: left;
    display: inline-block;
    margin-bottom: 0;
    img {
      width: 100%;
    }
  }
  .details {
    padding: 5px;
    margin-left: 315px;
    .detail {
      .category, .time {
        margin: 0 10px;
      }
      .category {
        margin: 0;
      }
    }
    h1 {
      margin: 0;
      margin-top: 5px;
      margin-bottom: 10px;
      font-size: 20px;
      line-height: 28px;
      a {
        color: #000;
        text-decoration: none;
        transition: all .5s;
        -moz-transition: all .5s;
        -webkit-transition: all .5s;
        -o-transition: all .5s;
        &:hover {
          color: color($colors, primary);
        }
      }
    }
    p {
      line-height: 26px;
    }
  }
  footer {
    margin-top: 0;
  }
}

.line {
  display: inline-block;
  width: 100%;
  height: 4px;
  background-color: #f2f2f2;
  margin: 30px 0;
  position: relative;
  &.thin {
    height: 2px;
  }
  &.transparent {
    background-color: transparent;
  }
  &.little {
    margin: 10px 0;
  }
  &.top {
    margin-top: 50px;
  }
  div {
    margin: 0;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    font-size: 16px;
    background-color: #fff;
    text-transform: uppercase;
    padding-left: 20px;
    padding-right: 20px;
    color: #000;
  }
}

.title-col {
  font-size: 18px;
  position: relative;
  margin-top: 0;
  &:after {
    content: ' ';
    position: absolute;
    bottom: -10px;
    width: 15px;
    background-color: color($colors, primary);
    height: 3px;
    left: 0;
  }
}

.body-col {
  margin-top: 30px;
}

.article-mini {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  .inner {
    display: inline-block;
    height: 100%;
  }
  .padding {
    padding: 0 10px;
    margin-left: 85px;
  }
  figure {
    float: left;
    display: inline-block;
    width: 80px;
    height: 60px;
    overflow: hidden;
    margin: 0;
    img {
      width: 100%;
    }
  }
  h1 {
    margin: 0;
    line-height: 22px;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 14px;
    a {
      color: #000;
    }
  }
  .detail {
    margin-top: 5px;
    .category {
      margin-left: 0;
      a {
        text-transform: uppercase;
        color: color($colors, primary);
      }
    }
  }
}

.tags-list {
  margin: 0;
  padding: 0;
  padding-left: 15px;
  li {
    padding: 5px;
    a {
      color: #000;
      position: relative;
      text-decoration: none;
      transition: all .3s;
      -moz-transition: all .3s;
      -webkit-transition: all .3s;
      -o-transition: all .3s;
      &:hover {
        opacity: .8;
      }
      &:after {
        position: absolute;
        content: ' ';
        left: 0;
        bottom: -1px;
        width: 0;
        height: 1px;
        background-color: color($colors, primary);
        transition: all .3s;
        -moz-transition: all .3s;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
      }
      &:hover:after {
        width: 100%;
      }
    }
  }
}

aside {
  margin-bottom: 40px;
  display: inline-block;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
  .aside-title {
    @include title_block();
  }
  .aside-body {
  }
  .article-mini {
    margin-bottom: 10px;
    padding-bottom: 10px;
    figure {
      margin-bottom: 0;
    }
    h1 {
      margin-top: -3px;
    }
  }
  .nav li {
    a {
      background-color: #F0F0F0;
      color: #333;
      padding: 12px;
      font-size: 14px;
      border-radius: 0 !important;
      i:before {
        font-size: 18px;
        margin-right: 5px;
      }
    }
    &.active a {
      background-color: #fff;
      color: #000;
      border-top: 2px solid color($colors, primary) !important;
      i {
        color: color($colors, primary);
      }
    }
  }
  .tab-pane {
    border: 1px solid #ddd;
    border-top: none;
    padding: 20px;
  }
  .line {
    margin: 40px 0;
  }
  .sponsored {
    padding: 0;
    margin: 0 -5px;
    li {
      float: left;
      display: inline-block;
      width: 50%;
      padding: 5px;
      a img {
        width: 100%;
      }
    }
  }

  .ads {
    img {
      width: 100%;
    }
    figcaption {
      font-size: 12px;
      margin-top: 3px;
      color: #989898;
    }
  }
}

@keyframes loading {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}


.article-fw {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  &:first-child {
    margin-bottom: 0;
  }
  figure {
    overflow: hidden;
    width: 100%;
    height: 178px;
    img {
      width: 100%;
    }
  }
  .details {
    h1 {
      margin: 0;
      font-size: 24px;
      line-height: 34px;
      a {
        color: #000;
        text-decoration: none;
        &:hover {
          color: color($colors, primary);
        }
      }
    }
    p {
      margin-top: 15px;
      line-height: 24px;
    }
  }
}

.comments {
  .media-left {
    width: 70px;
    height: 70px;
    overflow: hidden;
  }
  .media-body p {
    font-size: 14px;
    line-height: 22px;
  }
  .detail {
    display: flex;
    font-size: 14px;
    .time {
      display: inline-block;
      margin: 0 5px;
    }
    .link {
      display: inline-block;
      margin: 0 5px;
      a {
        color: color($colors, primary);
      }
    }
    .time {
      color: #989898;
    }
    .divider {
      height: 4px;
      width: 4px;
      background-color: #ddd;
      margin-top: 8px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.carousel#featured {
  .carousel-control {
    background-color: transparent;
    background-image: none;
    bottom: initial;
    width: auto;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    left: initial;
    right: initial;
    opacity: 0;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    span:before {
      font-size: 50px;
    }
    &.left {
      left: 0;
      right: initial;
    }
  }
  &:hover .carousel-control.left {
    left: 20px;
    right: initial;
  }
  .carousel-control.right {
    right: 0;
    left: initial;
  }
  &:hover .carousel-control {
    &.right {
      right: 20px;
      left: initial;
    }
    opacity: 1;
  }
}

.box-wrapper {
  width: 400px;
  margin: 0 auto;
}

.box {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 30px rgba(0,0,0,.05);
  &.box-border {
    box-shadow: none;
    border: 1px solid #f2f2f2;
  }
  .box-body {
    padding: 30px;
    h4 {
      margin-bottom: 30px;
    }
  }
}

.title-line {
  display: table;
  width: 100%;
  padding: 20px 0;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  color: #ddd;
  &:before, &:after {
    border-top: 2px dashed #eee;
    content: '';
    display: table-cell;
    position: relative;
    top: 0.5em;
    width: 45%;
  }
  &:before { right: 1.5%; }
  &:after { left: 1.5%; }
}

.with-sidebar {
  display: inline-block;
  width: 100%;
  .sidebar {
    &.sidebar-sticky {
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: $sidebar;
      z-index: 2000;
      background-color: #fff;
      box-shadow: 0 0 40px rgba(0,0,0,.05);
    }
    .sidebar-brand {
      width: 100%;
      padding: 50px;
      img {
        width: 100%;
      }
    }
    .sidebar-nav {
      ul {
        padding: 0;
        margin: 0;
        li {
          display: inline-block;
          width: 100%;
          a {
            display: block;
            padding: 13px 30px;
            text-decoration: none;
            color: #000;
          }
          &.active a, a:hover, &:focus {
            background-color: #F73F52;
            color: #fff;
          }
        }
      }
    }
  }
  .has-sidebar {
    float: left;
    padding-left: $sidebar + 30px;
    padding-right: 20px;
    display: inline-block;
    width: 100%;
  }
  footer {
    padding: 15px 0;
    border-top: 1px solid #f9f9f9;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    color: #29292b;
  }
}

.faq {
  display: inline-block;
  width: 100%;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
  .faq-title {
    h2 {
      font-size: 22px;
      margin: 0;
    }
  }
  .faq-body {
    margin-top: 10px; 
  }
}

@media screen and (max-width: 768px) {
  .with-sidebar {
    .sidebar {
      display: none;
    }
    .has-sidebar {
      padding: 0 20px;
    }
  }
  .for-tablet {
    display: block !important;
  }

  footer.footer {
    .block {
      margin-bottom: 30px;
    }
    .line {
      margin: 0;
      margin-bottom: 30px;
    }
  }
  .divider-top {
    margin-top: 50px;
  }

  .sidebar {
    position: fixed;
    top: 0;
    right: -$mobile_nav_width;
    height: 100%;
    width: $mobile_nav_width;
    z-index: 2001;
    background-color: #fff;
    overflow: auto;
    transition: $general-transition;
    -webkit-transition: $general-transition;
    -o-transition: $general-transition;
    -moz-transition: $general-transition;
    &.active {
      right: 0;
    }
    .sidebar-title {
      margin: 0 -15px;
      height: $nav_height - 2;
      text-align: center;
      border-bottom: 1px solid #f2f2f2;
      letter-spacing: .5px;
      font-weight: 700;
      line-height: 55px;
      font-family: 'Raleway', sans-serif;
      margin-bottom: 30px;
    }
  }

  .trending-tags {
    margin-bottom: 20px;
  }

  .search-toggle {
    display: none;
  }

  nav.menu {
    height: $nav_height;
    .mobile-toggle {
      display: inline-block;
    }
    ul.nav-list {
      position: fixed;
      width: $mobile_nav_width;
      height: 100%;
      top: 0;
      right: -$mobile_nav_width;
      background-color: #fff;
      z-index: 3000;
      overflow: auto;
      transition: $general-transition;
      -webkit-transition: $general-transition;
      -o-transition: $general-transition;
      -moz-transition: $general-transition;
      &.active {
        right: 0;
      }
      li {
        display: block;
        float: none;
        position: static;
        padding-top: 2px;
        padding-bottom: 2px;
      }
      > li {
        &:first-child {
          margin-left: 0;
          text-align: center;
          position: relative;
          .back {
            z-index: 9;
            position: absolute;
            height: 100%;
            width: 50px;
            line-height: 55px;
            font-size: 20px;
          }
          a {
            border-bottom: 1px solid #f2f2f2;
            &:after {
              display: none;
            }
          }
        }
        > a {
          display: block;
          .badge {
            right: 10px;
            top: 5px;
          }
        }
        .dropdown-menu {
          box-shadow: none;
          position: absolute;
          top: 57px;
          left: initial;
          right: -$mobile_nav_width;
          height: calc(100% - 55px);
          height: -webkit-calc(100% - 55px);
          height: -moz-calc(100% - 55px);
          height: -o-calc(100% - 55px);
          width: $mobile_nav_width;
          transform: scale(1);
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -o-transform: scale(1);
          visibility: visible;
          display: none;
          opacity: 1;
          &.active {
            right: 0;
          }
          > li {
            > a {
              width: auto;
              &:hover {
                &:after {
                  transform: scale(105);
                  -webkit-transform: scale(105);
                  -moz-transform: scale(105);
                  -o-transform: scale(105);
                }                
              }
            }
            ul {
              top: 0;
            }
          }

          [class^="col-"] {
            margin-bottom: 30px;
          }
        }
      }
    }
  }

  header.primary {
    &.up {
      top: -286px;
    }
  }

  .firstbar {
    text-align: center;
    .brand {
      float: none;
      img {
        float: none;
        display: inline-block;
        margin-bottom: 15px;
      }
      h2 {
        display: block;
        float: none;
        border: none;
        margin: 0;
        padding: 0;
        width: initial;
      }
    }
    .search {
      margin: 0 70px;
    }
    .right {
      float: none;
      display: block;
      margin: 10px auto;
    }
  }
}

@media screen and (max-width: 425px) {
  .article-list {
    figure {
      width: 100%;
      height: 200px;
      margin-bottom: 10px;
    }
    .details {
      margin-left: 0;
     .detail {
        display: inline-flex;
        display: -webkit-inline-box;
      }
    }
  }
  .line div {
    font-size: 14px;
  }
  .featured {
    height: 200px;
    .details {
      h1 {
        font-size: 16px;
      }
      .category a {
        padding: 3px 6px;
      }
    }
  }

  .firstbar {
    .search {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .topbar {
    .topbar-nav, ul.info {
      li {
        a {
          i {
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
            display: block;
            font-size: 22px;
          }
          span {
            display: none;
          }
        }
      }
    }
  }

  .for-mobile {
    display: block !important;
  }

  .nav-icons {
    display: none;
  }

  .box-wrapper {
    width: 100%;
  }
  .box {
    .box-body {
      padding: 20px;
    }
  }
}

@media screen and (max-width: 320px) {
  nav.menu {
    ul.nav-list {
      width: $mobile_nav_width - 30px;
      right: -$mobile_nav_width - 30px;
      > li {
        .dropdown-menu {
          width: $mobile_nav_width - 30px;
          right: -$mobile_nav_width - 30px;      
        }        
      }
    }
  }

  .sidebar {
    width: $mobile_nav_width - 30px;
    right: -$mobile_nav_width - 30px;      
  }

  article figure {
    height: 200px;
  }
}

footer.footer {
  background-color: color($colors, footer);
  color: color($colors, footerText);
  padding: 20px 0;
  padding-top: 50px;
  border-top: 5px solid color($colors, primary);
  a {
    color: color($colors, footerText);
  }
  .social {
    a {
      color: #fff;
    }
  }
  .line {
    background-color: #292929;
    height: 1px;
    margin: 30px 0;
  }
  .block {
    .block-title {
      font-size: 16px;
      position: relative;
      margin: 0;
      color: #fff;
      .right {
        float: right;
        font-size: 14px;
        a {
          color: #ddd;
        }
      }
      &:after {
        content: ' ';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 15px;
        height: 2px;
        background-color: color($colors, primary);
      }
    }
    .block-body {
      margin-top: 30px;
      &.no-margin {
        margin: 0;
      }
    }
  }
  .footer-nav-horizontal {
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      a {
        display: block;
        margin: 0 10px;
        margin-bottom: 10px;
        font-size: 12px;
        text-decoration: none;
        float: left;
        transition: all .5s;
        -moz-transition: all .5s;
        -webkit-transition: all .5s;
        -o-transition: all .5s;
        &:hover {
          color: color($colors, primary);
        }
      }
      &:first-child a {
        margin-left: 0;
      }
      &:not(:last-child):after {
        content: '/';
        float: left;
        line-height: 14px;
      }
    }
  }
  .footer-nav {
    padding: 0;
    margin: 0;
    padding-left: 17px;
    li {
      list-style: square;
      a {
        padding: 3px;
        display: inline-block;
        font-size: 14px;
        transition: all .3s;
        -moz-transition: all .3s;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        &:hover {
          color: color($colors, primary);
        }
      }
    }
  }
  .foot-logo {
    width: 140px;
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }
  .brand-description {
    line-height: 24px;
  }
  .newsletter {
    background-color: transparent;
    border: none;
    padding: 0;
    .btn {
      margin-top: 3px;
      border-radius: 3px;
    }
    .input-group {
      position: relative;
      display: inline-block;
      width: 100%;
    }
    .input-group-addon {
      background-color: transparent;
      border-color: transparent;
      position: absolute;
      left: 2px;
      top: 0;
      z-index: 9;
      height: 40px;
      line-height: 26px;
      i:before {
        transform: scale(1.4);
        -webkit-transform: scale(1.4);
        -moz-transform: scale(1.4);
        -o-transform: scale(1.4);
      }
    }
    .form-control {
      padding-left: 40px;
    }
  }

  .article-mini {
    .detail {    
      .time, .category a {
        color: color($colors, footerText);
      }
    }
  }
  .tags {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      display: inline-block;
      a {
        padding: 7px 12px;
        color: color($colors, footerText);
        border: 1px solid color($colors, footerText);
        margin: 0 5px;
        text-decoration: none;
        display: inline-block;
        margin-bottom: 5px;
        transition: all .3s;
        -moz-transition: all .3s;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        font-size: 14px;
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          border-color: color($colors, primary);
          color: #fff;
        }
      }
    }
  }
  .copyright {
    text-align: center;
    margin-top: 70px;
    color: color($colors, footerText);
    border-top: 1px solid #222;
    padding-top: 35px;
    padding-bottom: 20px;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    a {
      text-decoration: underline;
    }
    div {
      margin-top: 7px;
      i {
        color: color($colors, danger);
        padding: 0 3px;
      }
    }
  }
  .article-mini {
    margin-bottom: 23px;
    .inner {
      border-bottom: 0;
    }
    h1 {
      font-size: 14px;
      a {
        color: #ddd;
      }
    }
    .detail .category a {
      color: color($colors, footerText);
    }
  }
  .btn-magz {
    border-color: #fff;
    border-radius: 3px;
    font-size: 14px;
    padding-left: 13px;
    padding-right: 13px;
  }
}

// single
section.single {
  .line {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  article.main-article {
    header {
      margin: 0;    
      h1 {
        margin: 0;
        line-height: 1.1em;
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 10px;
      }
      .details {
        font-size: 10px;
        color: #989898;
        font-weight: 300;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding: 0;
        margin: 0;
        li {
          position: relative;
          display: inline-block;
          float: left;
          margin-right: 30px;
          &:after {
            content: '.';
            position: absolute;
            font-size: 30px;
            top: -2px;
            margin-left: 10px;
            line-height: 0;
          }
          a {
            color: color($colors, primary);
          }

          &:last-child {
            &:after {
              content: '';
            }
          }
        }
      }
    }

    .main {
      padding-top: 30px;
      margin-top: 20px;
      a {
        color: color($colors, primary);
      }

      p {
        font-size: 16px;
        color: #181818;
        margin-bottom: 20px;
        line-height: 30px;
      }

      .featured {
        height: auto;
        position: relative;
        overflow: initial;
        margin-bottom: 0;

        figure {
          position: relative;
          height: auto;
          figcaption {
            font-size: 12px;
            margin-top: 5px;
            text-align: center;
            color: #686868;
          }
        }
      }

      h1,h2,h3,h4,h5,h6 {
        line-height: 32px;
        margin-top: 50px;
      }
    }
  }

  footer {
    margin-top: 30px;
    text-align: left;
    display: flex;
    display: -webkit-box;
    flex-wrap: wrap;
    .col:first-child {
      width: 60%;
    }
    .col:last-child {
      width: 40%;
      text-align: right;
      .love {
        float: none;
        border: 2px solid #989898;
        border-radius: 30px;
        display: inline-block;
        padding: 3px 12px;
        i {
          transform: scale(.8);
          -webkit-transform: scale(.8);
          -moz-transform: scale(.8);
          -o-transform: scale(.8);
          &.animated {
            top: 3px;
            left: 12px;
          }
        }
        &.active {
          background-color: color($colors, primary);
          color: #fff;
          border-color: color($colors, primary);
        }
      }
    }
    h1 {
      font-size: 18px;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      margin-bottom: 10px;
      i {
        margin-right: 5px;
      }
    }
    .tags {
      margin: 0;
      padding: 0;
      li {
        float: left;
        display: block;
        a {
          display: block;
          margin-bottom: 5px;
          padding: 3px 7px;
          border: 1px solid #989898;
          margin-right: 5px;
          color: #000;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 10px;
          letter-spacing: 1px;
          &:hover {
            border-color: #000;
            color: color($colors, primary);
          }
        }
      }
    }
  }

  article.related {
    .inner {
      box-shadow: none;
      .padding {
        padding: 0;
      }
    }
    .detail {
      .time {
        color: #989898;
      }
      .category {
        margin-left: 0;
      }
    }
  }
}

blockquote {
  font-style: italic;
  font-weight: 300;
}

.sharing {
  display: inline-block;
  width: auto;
  padding-bottom: 20px;
  margin-top: 20px;
  .title {
    i {
      margin-right: 5px;
    }
    font-size: 12px;
    margin-bottom: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
  }
  ul {
    li {
      float: left;
      &.count {
        text-align: center;
        font-size: 30px;
        color: color($colors, primary);
        font-weight: 700;
        margin-left: 10px;
        display: inline-block;
        float: left;
        margin-top: -10px;
        div {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 300;
          color: #000;
          margin-top: -5px;
          display: block;
        }
      }
      a {
        text-decoration: none;
        width: auto;
        height: auto;
        padding-right: 25px;
        padding-left: 25px;
        i {
          margin-right: 10px;
        }

        &:hover {
          color: #fff !important;
          opacity: .8;
        }
      }
    }
  }
}

.author {
  display: inline-block;
  width: 100%;
  figure {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0;
    float: left;
    img {
      width: 100%;
    }
  }
  .details {
    margin-left: 120px;
    .job {
      font-size: 12px;
      color: #898989;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .name {
      margin: 0;
      margin-top: 5px;
    }
    p {
      margin-top: 10px;
      font-size: 15px;
      line-height: 26px;
    }
    .social {
      li {
        a {
          width: 30px;
          height: 30px;
          line-height: 30px;
          i:before {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.comments {
  .title {
    font-weight: 400;
    margin-bottom: 20px;
    a {
      font-size: 10px;
      display: inline-block;
      margin-left: 10px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }

  .comment-list {
    margin-top: 10px;

    .item {
      border: 1px solid #ddd;
      padding: 20px;
      margin-bottom: 20px;

      .user {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;

        figure {
          width: 65px;
          height: 65px;
          overflow: hidden;
          border-radius: 50%;
          float: left;
          display: inline-block;
          img {
            width: 100%;
          }
        }

        .details {
          margin-left: 85px;
          .name {
            margin: 0;
            margin-bottom: 3px;
            margin-top: 15px;
            font-weight: 700;
          }
          .time {
            color: #999;
          }
          .description {
            font-size: 15px;
            margin-top: 20px;
            line-height: 26px;
          }
        }
      }

      .reply-list {
        padding-left: 50px;
        .item {
          border: none;
        }
      }
    }

    &.sm {
      .item {
        border: none;
        padding: 0 0 5px 0;
        margin-bottom: 15px;
        border-bottom: 1px solid #f2f2f2;
        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;
        }
        .user {
          figure {
            width: 45px;
            height: 45px;
          }
          .details {
            margin-left: 60px;
            .name {
              margin-top: 5px;
              font-size: 18px;
            }
            .time {
              font-size: 12px;
            }
            .description {
              margin-top: 10px;
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  .form-control {
    &:focus {
      border-color: #484848;
    }
  }

  textarea.form-control {
    height: 180px;
    resize: none;
  }

  .btn {
    padding-left: 25px;
    padding-right: 25px;
  }

  form {
    margin-top: 30px;
  }
}

.page {
  text-align: center;
  .breadcrumb {
    margin-top: 20px;
  }
  .page-title {
    margin: 0;
    margin-top: 10px;
  }
  .page-subtitle {
    font-weight: 300;
    font-size: 12px;
    margin: 0;
    margin-top: 5px;
    font-family: 'Lato';
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  .line {
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .page-description {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    color: #484848;
    margin-top: 20px;
    text-align: left;
    h1 {
      color: #000;
      margin-bottom: 7px;
    }
    p {
      margin-bottom: 20px;
    }
  } 
  .question {
    font-weight: 700;
    text-align: center;
    a {
      margin-left: 10px;
    }
  }
  form.contact {
    margin-top: 30px;
    .form-group {
      textarea {
        height: 200px;
      }
    }
  }

  .element-block {
    .element-title {
      margin-bottom: 30px;
      position: relative;
      :first-child {
        margin-top: 0;
      }
      &:after {
        content: ' ';
        width: 15px;
        height: 2px;
        background-color: color($colors, primary);
        bottom: -5px;
        left: 0;
        position: absolute;
      }
    }
    .element-body {
      display: inline-block;
      width: 100%;
      .code-block {
        margin-top: 20px;
        .code-title {
          margin-bottom: 5px;
          font-weight: 500;
        }
      }
    }
    .colors {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        width: 80px;
        height: 80px;
        margin-right: 10px;
        margin-bottom: 40px;
        &.primary {
          background-color: color($colors, primary);
        }
        &.secondary {
          background-color: color($colors, secondary);
        }
        &.success {
          background-color: color($colors, success);
        }
        &.warning {
          background-color: color($colors, warning);
        }
        &.info {
          background-color: color($colors, info);
        }
        &.danger {
          background-color: color($colors, danger);
        }
        .details {
          h1 {
            margin: 0;
            font-size: 14px;
            margin-top: 87px;
            text-align: center;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.carousel-1 {
  .owl-dots {
    .owl-dot {
      span {
        margin: 5px;
      }
      &.active {
        span {
          background-color: color($colors, primary);        
          transform: scale(1.3);
          -webkit-transform: scale(1.3);
          -moz-transform: scale(1.3);
          -o-transform: scale(1.3);
        }
      }
    }
  }
  .owl-nav {
    transition: $general-transition;
    -webkit-transition: $general-transition;
    -o-transition: $general-transition;
    -moz-transition: $general-transition;
    opacity: 0;
    .owl-prev, .owl-next {
      margin-top: 10px;
      position: absolute;
      top: 50%;
      transform: translate(0,-50%);
      -webkit-transform: translate(0,-50%);
      -moz-transform: translate(0,-50%);
      -o-transform: translate(0,-50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      font-size: 26px;
      line-height: 42px;
      text-align: center;
      transition: $general-transition;
      -webkit-transition: $general-transition;
      -o-transition: $general-transition;
      -moz-transition: $general-transition;
      opacity: .7;
      &:hover {
        background-color: color($colors, primary);
      }
    }
    .owl-prev {
      left: 10px;
    }
    .owl-next {
      right: 10px;
    }
  }
  &:hover {
    .owl-nav {
      opacity: 1;
    }
  }
}

.video-list {
  padding: 0;
  margin: 0;
  li {
    display: block;
    margin-bottom: 20px;
    a {
      figure {
        height: 200px;
        background-color: #f2f2f2;
        position: relative;
        overflow: hidden;
        .duration {
          position: absolute;
          bottom: 10px;
          right: 10px;
          background-color: #212121;
          color: #fff;
          padding: 3px 7px;
          font-size: 12px;
        }
        .play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          line-height: 105px;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-color: #fff;
          color: #000;
          text-align: center;
          i {
            font-size: 30px;
          }
        }
      }
      .desc {
        margin-top: 10px;
        .title {
          display: inline-block;
          font-size: 18px;
          text-decoration: none;
          margin: 0;
          &.loading {
            background-color: #f2f2f2;
            width: 200px;
            height: 20px;
          }
        }
        .author {
          display: block;
          color: #999;
          margin-top: 3px;
          &.loading {
            background-color: #f2f2f2;
            width: 100px;
            height: 12px;
          }
        }
      }
    }
  }
}

.checkbox-group {
  margin-bottom: 5px;
  .group-title {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 15px;
    display: inline-block;
  }
  [class^="icheckbox"],[class^="iradio"] {
    margin-right: 5px;
  }
}

.nav-tabs-group {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #ddd;
  .nav-tabs-list {
    padding: 0;
    margin: 0;
    li {
      float: left;
      display: block;
      a {
        display: block;
        padding: 10px 20px;
        color: #999;
        font-weight: 400;
        text-decoration: none;
        &:hover {
          color: color($colors, dark);
        }
      }
      &.active {
        a {
          color: color($colors, dark);
          border-bottom: 2px solid color($colors, primary);
        }
      }
    }
  }
  .form-control {
    height: 30px;
    padding: 0;
    margin-top: 3px;
  }
  .nav-tabs-right {
    float: right;
    display: inline-block;
    width: 100px;
  }
}

.featured-author {
  display: block;
  .featured-author-inner {}
  .featured-author-cover {
    width: 100%;
    height: 250px;
    display: inline-block;
    background-size: cover;
    background-position: center;
    position: relative;
    .badges {
      position: absolute;
      top: 10px;
      left: 10px;
      .badge-item {
        display: inline-block;
        padding: 5px 15px;
        border-radius: 30px;
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        background-color: color($colors, dark);
        letter-spacing: 1px;
      }
    }
    .featured-author-center {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      text-align: center;
    }
    .featured-author-picture {
      margin: 0 auto;
      width: 80px;
      height: 80px;
      overflow: hidden;
      border-radius: 50%;
      border: 2px solid #fff;
      img {
        width: 100%;
      }
    }
    .featured-author-info {
      .name {
        font-size: 18px;
        color: #fff;
        margin: 0;
        margin-top: 10px;
      }
      .desc {
        font-size: 12px;
        margin-top: 3px;
        color: #ddd;
      }
    }
  }
  .featured-author-body {
    box-shadow: 0 0 3px #ddd;
    margin-top: -40px;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    z-index: 1;
    border-radius: 3px;
    background-color: #fff;
    line-height: 20px;
    .featured-author-count {
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid #f2f2f2;
      .item {
        float: left;
        width: calc(100% / 3);
        width: -webkit-calc(100% / 3);
        width: -moz-calc(100% / 3);
        width: -o-calc(100% / 3);
        text-align: center;
        padding: 5px 0;
        border-right: 1px solid #f2f2f2;
        transition: $general-transition;
        a {          
          &:hover {
            text-decoration: none;
            .name {
              color: color($colors, secondary);
            }
          }      
        }
        &:last-child {
          border-right: 0;
        }
        .name {
          text-transform: uppercase;
          font-size: 12px;
          color: color($colors, primary);
          font-weight: 500;
          -webkit-transition: $general-transition;
          -o-transition: $general-transition;
          -moz-transition: $general-transition;
        }
        .value {
          font-size: 18px;
          font-weight: 800;
          color: #666;
        }
        .icon {
          padding-top: 13px;
          margin: 0 auto;
          display: inline-block;
          font-size: 12px;
          div {
            float: left;
            display: inline-block;
            text-transform: uppercase;
            font-weight: 600;
          }
          i {
            display: inline-block;
            float: left;
            margin-left: 5px;
          }
        }
      }
    }
    .featured-author-quote {
      padding: 10px;
      font-size: 14px;
      font-family: 'Raleway', sans-serif;
      font-style: oblique;
      color: #999;
      text-align: center;
    }
    .featured-author-footer {
      display: inline-block;
      width: 100%;
      text-align: center;
      margin-top: 10px;
      a {
        display: block;
        padding: 5px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
        font-size: 12px;
        border-top: 1px solid #f2f2f2;
      }
    }
    .block {
      display: inline-block;
      padding: 15px;
      .block-title {
        @include title_block(false,14px);
      }
      .block-body {
        margin-top: 10px;
      }
    }
  }
}

.item-list-round {
  padding: 0;
  margin: 0;
  li {
    float: left;
    display: block;
    &.hidden {
      display: none;
    }
    a {
      display: block;
      width: 60px;
      height: 60px;
      overflow: hidden;
      border-radius: 5px;
      margin-right: 5px;
      margin-bottom: 5px;
      background-size: cover;
      background-position: center;
      position: relative;
      &:hover {
        opacity: .7;
      }
      .more {
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparentize(#000, .5);
        width: 100%;
        height: 100%;
        border-radius: 5px;
        color: #fff;
        text-align: center;
        line-height: 64px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

.aside-title, .title-col, .best-of-the-week {
  .carousel-nav {
    float: right;
    display: inline-block;
    .prev, .next {
      display: block;
      line-height: 24px;
      width: 25px;
      height: 25px;
      border: 2px solid #ddd;
      color: #999;
      text-align: center;
      float: left;
      cursor: pointer;
      transition: $general-transition;
      -webkit-transition: $general-transition;
      -o-transition: $general-transition;
      -moz-transition: $general-transition;
      &:hover {
        background-color: #f2f2f2;
      }
    }
    .prev {
      border-right: 0;
    }
  }
}

.video_list_youtube {
  .fancybox-caption-wrap {
    h4 {
      color: #fff;
      font-size: 20px;
    }
    .author {
      opacity: .8;
    }
    a {
      font-size: 12px;
    }
  }

  .fancybox-slider-wrap {
  }
}

.banner {
  position: relative;
  text-align: center;
  margin: 20px 0;
  &.header {
    margin: 10px 0;
    &:after {
      content: ' ';
    }
  }
  img {
    max-width: 100%;
  }
  &:after {
    content: '- advertisement -';
    font-size: 12px;
    color: #999;
    letter-spacing: .5px;
    margin: 0 auto;
  }
}

// Magnific Popup Custom
.mfp-wrap {
  z-index: 2002;
}

.mfp-bg {
  z-index: 2001;
}